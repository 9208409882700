	
	function firmarDocumento() {
		var sistema              = "";
		var servidorFirma 		 = "";

		sistema       		= "NOE-2021";
		servidorFirma 		= "NOE-2021";

		idCompanyFastClient = document.getElementById("idCompanyFastClient").value
		userFastClient = document.getElementById("userFastClient").value
		documentSigningProcessFastClient = document.getElementById("documentSigningProcessFastClient").value
		idProcessFastClient = document.getElementById("idProcessFastClient").value
		subdomainFastClient = document.getElementById("subdomainFastClient").value 	
		boundingBoxFastClient = document.getElementById("boundingBoxFastClient").value 	
		HolographicPathFastClient = document.getElementById("HolographicPathFastClient").value 	
		HolographicPathRotateFastClient = document.getElementById("HolographicPathRotateFastClient").value 	
		pageFastClient = document.getElementById("pageFastClient").value 	
		methodOne = document.getElementById("methodOneFastClient").value 	
		methodTwo = document.getElementById("methodTwoFastClient").value 	
		methodUrl = document.getElementById("methodUrlFastClient").value 	
		apikey = document.getElementById("apikeyFastClient").value 	

		urlStepFuction = document.getElementById("urlStepFuction").value 	
		urlSignFastClientTransaction = document.getElementById("urlSignFastClientTransaction").value 
		methodSignFastClientTransaction  = document.getElementById("methodSignFastClientTransaction").value
		methodSignFastClientTransactionContinue  = document.getElementById("methodSignFastClientTransactionContinue").value

		var urlServicio = servidorFirma + '/SignFastService/GestionarProcesamientoFirmas/registrar/' + sistema;
		obtenerNumeroOperacion(idCompanyFastClient, userFastClient, documentSigningProcessFastClient,idProcessFastClient,subdomainFastClient,boundingBoxFastClient,HolographicPathFastClient,HolographicPathRotateFastClient,pageFastClient,methodOne,methodTwo,methodUrl,apikey,urlStepFuction,urlSignFastClientTransaction,methodSignFastClientTransaction,methodSignFastClientTransactionContinue);
		
	}

	function recibirRespuestaFirma(url, numeroProcesamiento) {
		
	    var respuesta = "";
		var ajax = crearAjax();
		ajax.open('GET', url, true);
		ajax.onreadystatechange = function() {
			if (ajax.readyState == 4) {
			    if (ajax.status == 200) {
			    	respuesta = ajax.responseText;
					document.getElementById("respuesta").value = respuesta;
					enviarMensaje();
				} else if (ajax.status == 404) {
					document.getElementById("respuesta").value = '0';
					enviarMensaje();
				}
			}
		};
		
		ajax.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		ajax.send();
	}

	function obtenerNumeroOperacion(idCompanyFastClient, userFastClient, documentSigningProcessFastClient,idProcessFastClient,subdomainFastClient,boundingBoxFastClient,HolographicPathFastClient,HolographicPathRotateFastClient,pageFastClient,methodOne,methodTwo,methodUrl,apikey,urlStepFuction,urlSignFastClientTransaction,methodSignFastClientTransaction,methodSignFastClientTransactionContinue){
		
	    var numeroProcesamiento = "";
			numeroProcesamiento = "123456789";
			
			var rutaSignFastClient = "SignFastClient://sign/$"                              + 
										idCompanyFastClient			+ "&" + 
										userFastClient 			+ "&" + 
										documentSigningProcessFastClient 			+ "&" + 
										idProcessFastClient 			+ "&" + 
										subdomainFastClient+ "&" +
										boundingBoxFastClient+ "&" +
										HolographicPathFastClient+ "&" +
										HolographicPathRotateFastClient+ "&" +
										pageFastClient+ "&" +
										methodOne+ "&" +
										methodTwo+ "&" +
										methodUrl+ "&" +
										apikey+ "&" +
										urlStepFuction+ "&" +
										urlSignFastClientTransaction+ "&" +
										methodSignFastClientTransaction + "&" +
										methodSignFastClientTransactionContinue;

			console.log(rutaSignFastClient)
			location.href = rutaSignFastClient;
		return numeroProcesamiento;
	}

	function crearAjax() {
		var xmlhttp = false;
		try {
			xmlhttp = new ActiveXObject("Msxml2.XMLHTTP");
		} catch (e) {
			try {
				xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
			} catch (E) {
				if (!xmlhttp && typeof XMLHttpRequest != 'undefined'){
					xmlhttp = new XMLHttpRequest();
				}
			}
		}
		return xmlhttp;
	}
	
	function obtenerRespuestaFirma(respuestaFirma){
		return respuestaFirma.split("»");
	}
	
	